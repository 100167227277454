@use "./variables.scss";
// VAR
$fontsize-email: 14px;
$lineheight-email: 22px;

// COLORS
:root{
    --editor-primary-color: red;
    --editor-secondary-color: red;
    --editor-tertiary-color: red;
}

.editor-link-modal{
    .modal-content{
        min-height: unset !important;
        padding: 30px;
    }
}
.editor-crop-modal{
    .modal-dialog{
        max-width: 740px;
        .modal-content{
            min-height: 320px !important;
            padding: 25px;
        }
    }
}
#link-button{
    i{
        font-size: 18px;
        margin: 0;
        color: #6C6E6D;
        transition: none;
    }
    &:hover i{
        color: var(--primary-color);
    }
}

.editor-tertiary-color{
    color: #333333;
    color: var(--editor-tertiary-color);
}

// IMAGES
.editor-image-placeholder{
    position: relative;
    overflow: hidden;
    z-index: 2;
    cursor: pointer;
    background-color: #f4f4f4;
    transition: .2s ease;
    transition: .1s ease;
    transition: 0s ease;
    float: left;
    img{
        transition: .12s linear;
        transition: .1s linear;
    }
    &>span{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        font-size: 12px;
        color: #c7c7c7;
        margin-top: 30px;
        transition: .2s ease;
    }
}
.editor-image-overlay{
    z-index: 1;
    border-radius: 3px;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #9292926a;
    &>span{
        padding: 7px;
        margin: 2px;
        border-radius: 100%;
        cursor: pointer;
        transition: .1s ease;
        opacity: 1;
        &:hover{
            opacity: 1;
            transform: scale(1.05);
        }
    }
    i.icon-image-edit {
        display: inline-block;
        width: 55px;
        height: 55px;
        background: url('/assets/img/editor/icon-image-edit.svg') center center no-repeat;
        background-size: contain;
    }
    i.icon-image-delete {
        display: inline-block;
        width: 55px;
        height: 55px;
        background: url('/assets/img/editor/icon-image-delete.svg') center center no-repeat;
        background-size: contain;
    }
}
.editor-image-overlay--url{
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    white-space: nowrap;
    padding: 5px 7px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    color: white;
    font-size: 14px;
    background: #0000001a;
    &:hover{
        color: white !important;
    }
}
.cropper{
    .move{
        border: none !important;
    }
}

// FORMATS
.format-normal-link{
    color: var(--editor-primary-color) !important;
    text-decoration: underline;
}
.format-email-link{
    color: var(--editor-primary-color) !important;
    text-decoration: underline;
}
.format-button-link{
    background-color: var(--editor-primary-color);
    color: #fff !important;
    border-radius: 3px;
    padding: 14px 28px;
    text-decoration: none !important;
    display: inline-block;
    line-height: 1;
}
.ql-align-center{
    text-align: center;
}
.ql-align-right{
    text-align: right;
}
.ql-align-justify{
    text-align: justify;
}
// lists
.ql-indent-1, .ql-indent-2, .ql-indent-3, .ql-indent-4, .ql-indent-5{
    padding-left: 0 !important;
}
.ql-indent-1{
    margin-left: 30px !important;
    list-style-type: circle !important;
    // counter-reset: list-2 list-3 list-4 list-5 !important;
    // counter-increment: list-1 !important;
}
ul ul li{
    list-style-type: circle !important;
}
.ql-indent-2{
    margin-left: 60px !important;
    // counter-reset: list-3 list-4 list-5 !important;
    // counter-increment: list-2 !important;
}
.ql-indent-3{
    margin-left: 90px !important;
    // counter-reset: list-4 list-5 !important;
    // counter-increment: list-3 !important;
}
.ql-indent-4{
    margin-left: 120px !important;
    // counter-reset: list-5 !important;
    // counter-increment: list-4 !important;
}
.ql-indent-5{
    margin-left: 150px !important;
    // counter-reset: unset !important;
    // counter-increment: list-5 !important;
}
// .ql-editor, .ql-output{
//     ol, ul{
//         counter-reset:list-1 list-2 list-3 list-4 list-5;
//         li{
//             counter-reset: list-1 list-2 list-3 list-4 list-5;
//             counter-increment: list-0;
//         }
//     }
// }

body div .quill-wrap{
    word-break: break-word;

    // TOOLTIP
    .ql-tooltip{
        z-index: 9;
        border: none;
        color: #333333;
        box-shadow: 0 0 13px 0 rgb(0 0 0 / 20%);
        border-radius: 2px;
        font-size: 14px;
        padding: 4px 14px;
        translate: -56px;
        .ql-action{
            display: none;
            // &::after{
            //     content: 'Wijzig';
            //     color: #4E85D8;
            //     text-decoration: underline;
            //     font-size: 12px;
            // }
        }
        .ql-remove{
            display: none;
            // &::before{
            //     content: 'Verwijder';
            //     color: #4E85D8;
            //     text-decoration: underline;
            //     font-size: 12px;
            // }
        }
        input[type=text]{
            font-size: 12px;
            height: 26px;
            border-radius: 3px;
            border-color: variables.$field-border-color;
            outline: none !important;
        }
        &::before{
            content: 'Link:';
            margin-right: 5px;
            display: none;
        }
        .ql-preview{
            color: var(--primary-color);
        }
    }

    .ql-editor, .ql-output{
        h1{
            color: #333333;
            color: var(--editor-tertiary-color);
        }
        h2{
            color: #333333;
            color: var(--editor-tertiary-color);
        }
    }

    // EDITOR
    .ql-container.ql-snow{
        border: none;
    }
    .ql-editor{
        padding: 0;
        overflow: visible;
    }
    .ql-editor.ql-blank::before{
        left: 0;
        right: 0;
        font-family: sans-serif;
        font-size: 16px;
        font-style: normal;
        color: var(--shade-5);
        top: 1px;
    }

    // TOOLBAR
    .ql-toolbar{
        border: none;
        padding: 4px 10px;
        background: #fff;
        box-shadow: 0 2px 8px 0 rgb(144 144 144 / 80%);
        border-radius: 2px;
        &.ql-toolbar--fixed{
            position: fixed;
            bottom: 16px;
            z-index: 999;
        }
    }
    .ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill{
        fill: #6C6E6D;
    }
    .ql-snow .ql-stroke{
        stroke: #6C6E6D;
    }
    .ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected{
        color: var(--primary-color);
    }
    .ql-snow.ql-toolbar button:hover .ql-fill, .ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill{
        fill: var(--primary-color) !important;
    }
    .ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter{
        stroke: var(--primary-color) !important;
    }
    .ql-active .ql-stroke{
        stroke: var(--primary-color) !important;
    }
    .ql-active .ql-fill{
        fill: var(--primary-color) !important;
    }

    li[data-list="ordered"] + li[data-list="bullet"]{
        margin-top: 18px;
    }

    .ql-editor, .ql-output{
        // FORMATS
        *{
            font-family: sans-serif;
        }
        // paragraphs
        p, 
        ul,
        ol
        {
            font-family: sans-serif;
            font-size: $fontsize-email;
            color: #404040;
            line-height: $lineheight-email;
            margin-bottom: 18px;
            padding: 0;
        }
        ul{
            list-style: disc;
            padding-left: 16px;
            li{
                padding-left: 0;
                list-style-type: disc;
            }
        }
        ol{
            list-style: decimal;
            padding-left: 16px;
            li{
                padding-left: 0;
                list-style-type: decimal;
            }
        }
        li::before{
            display: none !important;
        }
        li{
            *::before{
                display: none !important;
            }
            &[data-list="bullet"]{
                list-style-type: disc;
            }
        }
        p{
            font-family: sans-serif;
            font-size: $fontsize-email;
            color: #404040;
            line-height: $lineheight-email;
        }
        // decoration
        strong{
            font-weight: 600;
        }
        em{
            font-style: italic;
        }
        u{
            text-decoration: underline;
        }
        // heading
        h1{
            font-family: sans-serif;
            font-size: 22px;
            margin-bottom: 12px;
            line-height: 24px;
        }
        h2{
            font-family: sans-serif;
            font-size: $fontsize-email;
            margin-bottom: 8px;
            line-height: $lineheight-email;
            font-weight: 600;
        }
    }

}

.editor-block{
    position: relative;
    .editor-block-after-section{
        position: absolute;
        left: 100%;
        margin-left: 34px;
        border-left: 2px solid #DADDDB;
        top: 0;
        bottom: 0;
        padding-left: 18px;
        display: flex;
        align-items: center;
        &.active{
            color: var(--red-light);
            border-left-color: var(--red-pink);
            .im-icon-trash{
                color: var(--red-light) !important;
                pointer-events: all;
            }
            .delete-wrap{
                cursor: pointer;
                pointer-events: all;
            }
        }
        .im-icon{
            font-size: 18px;
            &.sortable-handle{
                color: var(--shade-6);
                margin-right: 20px;
                cursor: move;
                transition: none;
                &:hover{
                    color: var(--red-pink) !important;
                }
            }
            &.im-icon-trash{
                color: var(--shade-4);
                transform: translateY(-1px);
                // pointer-events: none;
                transition: none;
            }
        }
        .delete-wrap{
            display: flex;
            align-items: center;
            padding-top: 8px;
            padding-bottom: 8px;
            // pointer-events: none;
            z-index: 9;
            cursor: pointer;
            &:hover .im-icon-trash{
                color: var(--red-light) !important;
            }
            &>span{
                white-space: nowrap;
                margin-left: 4px;
                font-size: 12px;
            }
            .app-confirm-delete{
                bottom: calc(100% + 8px) !important;
            }
        }
    }
    .editor-block-inner{
        transition: .2s ease;
        &:hover + .editor-block-after-section{
            color: var(--red-light);
            border-left-color: var(--red-pink);
        }
    }
    .editor-block-empty{
        color: var(--shade-5);
        padding-top: 4px;
        padding-bottom: 4px;
        font-family: sans-serif;
        font-size: 16px;
        cursor: pointer;
        padding-left: 24px;
        padding-right: 24px;
    }
}
.editor-block-drag{
    position: relative;
    opacity: 1 !important;
    &.sortable-dragging{
        .sortable-handle{
            color: var(--red-pink) !important;
        }
        .editor-block-after-section{
            border-left-color: var(--red-pink) !important;
        }
        .editor-block-inner{
            background-color: var(--white);
            box-shadow: 0 2px 10px 0 rgb(144 144 144 / 30%);
            border-radius: 3px;
            opacity: .5 !important;
        }
        .hide-dragging{
            opacity: 0;
        }
    }
    &.sortable-ghost{
        .sortable-handle, .im-icon{
            opacity: 0 !important;
        }
        .editor-block-inner{
            border-radius: 3px;
        }
        .editor-block-after-section{
            border-left-color: transparent !important;
        }
    }
}
.editor-bg{
    max-width: 655px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 1;

    padding: 0 10px;
    max-width: 675px;
    border: 1px solid #D7DDE4;
    border-radius: 4px;
    margin: -10px;
}