$primary-color: #4FB0D4;
$primary-color-darker-alt: #479ebe;
$primary-color-lighter: #8acfe9;
$primary-color-lightest: #eaeff3;

// SECONDARY fixed
// old
$secondary-color: #61A0AF;
$secondary-color-lighter: #CFDFE8;
$secondary-color-lightest: #DFECEF;
// new
$secondary-color: #61A0AF;
$secondary-color-lighter: #CFDFE8;
$secondary-color-lightest: #EAF0F3;

// TERTIARY fixed
$tertiary-color: #407D8B;
$tertiary-color-lightest: #F6F9FA;

$hover-row--lighter: #F0F6F7;
$hover-row: #EAF0F3;

$text-color: #333;

$error-red: #DA3E52;
$error-red-light: #ff7383;
$error-red-alt: #EA525B;
$warning-orange: #ea9e52;
.error-red{color: $error-red};

$gray: #959595;
$link-blue: #4E85D8;
$field-border-color: #D7DDE4;


$modalSidePanelWidth: 380px;
$modalSidePanelWidth--1600: 300px;


// colors
:root{
    // variable colors
    --shade-0: #ffffff;
    --shade-1: #f5f5f5;
    --shade-2: #f1f1f1;
    --shade-3: #dadddb;
    --shade-4: #d8d8d8;
    --shade-5: #acacac;
    --shade-6: #909090;
    --shade-7: #606060;
    --shade-8: #555555;

    --text-color: #333333;
    --text-color-alt: #424745;
    --background-color: #ffffff;
    --white: #ffffff;
    --white-dark-mode-only: transparent;
    --highlight-background: #f1f9fe;
    --popup-background: #ffffff;
    --popup-background-alt: #F5F5F5;
    // --tertiary-background: #FCE7EC;

    // fixed colors
    // --secondary: #008ae3;
    // --tertiary: #ef476f;
    --green: #00af54;
    --orange: #ef8a17;
    --red: #d8040d;
    --red-pink: #ef476f;
    --red-background: #ffeded;
    --red-light: #ff7383;
    --white-fixed: #ffffff;
    --shade-1-fixed: #f5f5f5;

    // ivm
    // --primary-color: #4FB0D4;
    // --primary-color-dark: #479ebe;
    // --primary-color-light: #8acfe9;
    --secondary: #4FB0D4;
    --tertiary: #4FB0D4;
    --tertiary-background: #e7f7fc;
}

:root .theme--dark{
    --shade-0: #434343;
    --shade-1: #333333;
    --shade-2: #333333;
    --shade-3: #dadddb;
    --shade-4: #acacac;
    --shade-5: #acacac;
    --shade-6: #dadddb;
    --shade-7: #dadddb;
    --shade-8: #ffffff;

    --text-color: #ffffff;
    --text-color-alt: #ececec;
    --background-color: #333333;
    --white: #1d1d1d;
    --highlight-background: #3F474C;
    --white-dark-mode-only: #ffffff;
    --popup-background: #383D44;
    --popup-background-alt: #2a3039;
    // --tertiary-background: #32222D;

    // ivm
    --primary-color: #42bfec;
    --secondary: #42bfec;
    --tertiary: #42bfec;
    --tertiary-background: #222f32;
}

// font sizes
$fontsize-xl: 28px;
$fontsize-l: 18px;
$fontsize-m: 16px;
$fontsize-s: 14px;
$fontsize-xs: 13px;
$fontsize-xxs: 12px;
// $fontsize-xl: 32px;
// $fontsize-l: 20px;
// $fontsize-m: 18px;
// $fontsize-s: 16px;
// $fontsize-xs: 14px;
// $fontsize-xxs: 12px;

// dimensions
$sidePanelWidth: 400px;
$sidePanelWidth--1600: 340px;
$popoutPanelWidth: 440px;
$headerHeight: 56px;

// spacing
$spacing-xxl: 64px;
$spacing-xl: 48px;
$spacing-l: 40px;
$spacing-m: 32px;
$spacing-s: 24px;
$spacing-xs: 16px;
$spacing-xxs: 8px;
$spacing-xxxs: 6px;