@use "./variables.scss";
.table-wrap{
    padding-top: 20px;
}
.data-ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}
.table-component{
    .data-ellipsis{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
    }
    position: relative;
    &.loading-timed-out{
        .loading-timed-out-spinner{
            z-index: 1;
            position: absolute;
            top: 200px;
            left: 50%;
            transform: translateX(-50%);
        }
        td{
            transition: .3s ease !important;
            opacity: .2 !important;
        }
    }
    &.table-component--padded{
        td{
            padding: 0 !important;
            height: 1px;
            .pad{
                padding: 16px 14px;
                text-decoration: none!important;
                display: block;
                height: 100%;
                width: 100%;
            }
            &:first-of-type .pad{
                padding-left: 0;
            }
            &:last-of-type .pad{
                padding-right: 0;
            }
        }
        .shimmer-body{
            tr{
                td{
                    padding: 16px 0 !important;
                }
            }
        }
    }
}
.table{
    thead{
        :host-context( .theme--dark ) & {
            background-color: #6c6e6d;
        }
    }
    .shrink{
        width: .1%;
    }
    th, td{
        vertical-align: top;
        padding-left: 14px;
        padding-right: 14px;
        line-height: 21px;
        position: relative;
    }
    td{
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #F3F3F3;
        font-size: variables.$fontsize-s;
        font-weight: 300;
        max-width: 0;
        word-break: break-word;
        &.td--mw-0{
            max-width: 0;
        }
        .title{
            color: variables.$text-color;
            font-weight: 500;
        }
        .subtitle{
            margin-top: 1px;
            font-size: 12px;
            line-height: 16px;
        }
        &:first-of-type::after{
            content: '';
            position: absolute;
            background: #f1f9fe;
            top: -1px;
            left: -#{variables.$spacing-xs};
            height: calc(100% + 2px);
            width: variables.$spacing-xs;
            opacity: 0;
        }
        &:last-of-type::after{
            content: '';
            position: absolute;
            background: #f1f9fe;
            top: -1px;
            right: -#{variables.$spacing-xs};
            height: calc(100% + 2px);
            width: variables.$spacing-xs;
            opacity: 0;
        }
    }
    th{
        padding-top: 8px;
        padding-bottom: 8px;
        z-index: 1;
        &.sortable{
            cursor: pointer;
        }
        &.settings{
            cursor: pointer;
            display: table-cell;
            padding-left: 5px;
        }
        &:first-of-type{
            padding-left: 0;
        }
        &:last-of-type{
            padding-right: 0;
        }
        &>div{
            white-space: nowrap;
        }
        color: var(--primary-color);
        font-weight: 400;
        font-size: variables.$fontsize-s;
        border-top: none;
        &>div{
            z-index: 2;
        }
        border-bottom: 1px solid #E2E3E6 !important;
        i{
            position: relative;
            margin-left: 6px;
            &::after{
                content: "";
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 5px solid #E2E3E6;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                margin-top: 3px;
            }
            &::before{
                content: "";
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-bottom: 5px solid #E2E3E6;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                margin-top: -3px;
            }
            &.asc{
                &::before{
                    border-bottom: 5px solid var(--primary-color);
                }
            }
            &.desc{
                &::after{
                    border-top: 5px solid var(--primary-color);
                }
            }
        }
    }
    tr{
        // transition: .1s ease;
        position: relative;
        &.clickable{
            cursor: pointer;
            &:hover td{
                background-color: variables.$hover-row--lighter;
                &:first-of-type::after{
                    opacity: 1;
                }
                &:last-of-type::after{
                    opacity: 1;
                }
            }
            &.active td{
                background-color: variables.$hover-row--lighter;
                .title{
                    // color: var(--primary-color);
                    color: var(--primary-color);
                }
                &:first-of-type::after{
                    opacity: 1;
                }
                &:last-of-type::after{
                    opacity: 1;
                }
            }
        }
        td {
            transition: 0s;
            color: #717277;
            &:first-of-type::after{
                content: '';
                position: absolute;
                top: -1px;
                left: -40px;
                height: calc(100% + 2px);
                width: 40px;
                opacity: 0;
                border-top: 1px solid #F3F3F3;
                border-bottom: 1px solid #F3F3F3;
                background-color: variables.$hover-row--lighter;
            }
            &:last-of-type::after{
                content: '';
                position: absolute;
                top: -1px;
                right: -40px;
                height: calc(100% + 2px);
                width: 40px;
                opacity: 0;
                border-top: 1px solid #F3F3F3;
                border-bottom: 1px solid #F3F3F3;
                background-color: variables.$hover-row--lighter;
            }
            &:first-of-type{
                padding-left: 0;
            }
            &:last-of-type{
                padding-right: 0;
            }
        }
        &:first-of-type td{
            // &:first-of-type::after{
            //     border-top-color: #E2E3E6;
            // }
            // &:last-of-type::after{
            //     border-top-color: #E2E3E6;
            // }
        }
    }
    a{
        color: #717277;
    }
    .bullet--new:before{
        content: "•";
        color: #569ff9;
        font-size: x-large;
        margin-left: 6px;
        transform: translateY(3px);
        display: inline-block;
    }
}

.td-filename{
    padding-left: 8px !important;
}

.table-empty-line{
    td{
        border-bottom: none !important;
    }
}

.table, .table--detail, .table--detail--dash, .table--detail--slideout{
    .action-button{
        cursor: pointer;
        color: variables.$text-color;
        font-size: variables.$fontsize-xxs;
        text-decoration: none !important;
        background-color: #EEF2F5;
        background-color: variables.$hover-row;
        width: 24px;
        height: 24px;
        border-radius: 99px;
        transition: .1s ease;
        margin-top: -2px;
        margin-bottom: -2px;
        &.action-active{
            background-color: #EEF2F5 !important;
            background-color: variables.$hover-row !important;
        }
        &:hover, &.active{
            background-color: #dde5ea;
        }
        &>i{
            font-size: 14px;
            padding: 1px 5px;
        }
    }
}

.table--detail{
    h2{
        font-size: 25px;
        line-height: 32px;
        font-weight: 500;
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid #E5E5E5;
    }
    table{
        width: 100%;
        border-radius: 4px;
        border: 1px solid #EAF0F3;
        border-collapse: separate;
        thead{
            th{
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                color: var(--primary-color);
                background-color: #F9F9FA;
                padding: 10px 16px;
                border-bottom: 1px solid #EAF0F3;
                &:first-child{
                    border-top-left-radius: 4px;
                }
                &:last-child{
                    border-top-right-radius: 4px;
                }
            }
        }
        tbody{
            tr{
                td{
                    font-size: 14px;
                    line-height: 16px;
                    font-weight: 400;
                    color: var(--text-color);
                    padding: 10px 16px;
                    border-bottom: 1px solid #EAF0F3;
                    &:first-child{
                        font-weight: 500;
                    }
                    a{
                        color: var(--text-color);
                        text-decoration: underline;
                        font-size: 14px;
                        line-height: 16px;
                        font-weight: 400;
                    }
                }
                &:last-child{
                    td{
                        border-bottom: none;
                    }
                }
            }
        }
    }
    .detail-modal-table-add{
        padding: 18px 16px;
        display: flex;
        gap: 6px;
        align-items: center;
        i{
            cursor: pointer;
        }
        span{
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            color: var(--text-color);
            cursor: pointer;
        }
    }
    &.table--detail--form{
        &>tr{
            &>th{
                padding-top: 8px;
                padding-bottom: 8px;
                // border-bottom: none !important;
                &:first-of-type{
                    // padding-left: 0;
                }
                &:last-of-type{
                    // padding-right: 16px;
                }
            }
            &>td{
                padding-top: 8px;
                padding-bottom: 8px;
                // border-bottom: none;
                &:first-of-type{
                    // padding-left: 0;
                }
                &:last-of-type{
                    // padding-right: 16px;
                }
            }
        }
    }
    &.table--detail--dash{
        &>tr{
            &>th{
                background-color: transparent;
                border-bottom: 1px solid #EBEBEC;
                &:first-of-type{
                    padding-left: 0;
                }
                &:last-of-type{
                    padding-right: 0;
                }
            }
            &>td{
                padding-top: 8px;
                padding-bottom: 8px;
                border-bottom: 1px solid #E9EEF0;
                color: #3D3D3D;
                font-weight: 300;
                &:first-of-type{
                    padding-left: 0;
                }
                &:last-of-type{
                    padding-right: 0;
                }
            }
        }
    }
    &.table--detail--slideout{
        &>tr{
            &>th{
                background-color: transparent;
                border-bottom: 1px solid #EBEBEC;
                padding-top: 6px;
                padding-bottom: 6px;
                &:first-of-type{
                    padding-left: 0;
                }
                &:last-of-type{
                    padding-right: 0;
                }
            }
            &>td{
                vertical-align: middle;
                line-height: 24px;
                padding-top: 6px;
                padding-bottom: 6px;
                border-bottom: 1px solid #E9EEF0;
                color: #3D3D3D;
                font-weight: 300;
                &:first-of-type{
                    padding-left: 0;
                }
                &:last-of-type{
                    padding-right: 0;
                }
            }
        }
    }
}


.slideout-wrap{
    position: relative;
    background-color: #F6F6F6;
    // padding: 32px 24px 64px 92px;
    position: fixed;
    // overflow-y: auto;
    right: -800px;
    top: 0;
    height: 100vh;
    width: 800px;
    z-index: 999;
    transition: right .4s ease, opacity .3s ease;
    opacity: 0;
    pointer-events: none;
    min-width: 180px;
    max-width: calc(100vw - 300px);
    &.open{
        right: 0;
        box-shadow: -12px 0px 16px rgba(0, 0, 0, 0.12);
        opacity: 1;
        pointer-events: all;
    }
    .slideout-wrap-inner{
        overflow-y: auto;
        padding: 32px 24px 64px 92px;
        height: 100%;
    }
    .button-collapse{
        top: 32px;
        left: 32px;
    }
    .head-wrap{
       display: flex;
       justify-content: space-between;
       align-items: flex-start;
       padding-bottom: 32px;
        .title-wrap{
            padding-right: 40px;
            .title{
                font-size: 20px;
                line-height: 28px;
                color: variables.$text-color;
                font-weight: 500;
                word-break: break-word;
            }
            .sub{
                color: #717277;
                font-size: 16px;
                line-height: 26px;
                font-weight: 300;
                .sep-dot{
                    width: 4px;
                    height: 4px;
                    display: inline-block;
                    margin: 3px 6px;
                    background-color: var(--primary-color);
                    border-radius: 100%;
                }
            }
        }
    }
    .resize{
        left: 0;
        top: 0;
        bottom: 0;
        width: 4px;
        background-color: var(--primary-color);
        position: absolute;
        cursor: ew-resize;
        opacity: 0;
        transition: .1s;
        &:hover{
            opacity: .5;
        }
    }
    &.resizing{
        user-select: none;
        *{
            pointer-events: none;
        }
        .resize{
            opacity: 1;
            pointer-events: all !important;
        }
    }
}



// dashboard table

.table--dashboard{
    width: 100%;
    overflow-x: auto;
    // .app-list{
    //     margin-bottom: 0 !important;
    //     padding-top: 10px !important;
    //     li{
    //         border-bottom: none !important;
    //         border-top: 1px solid #E6E6E6;
    //         // color: #7C8590 !important;
    //         color: #acacac !important;
    //         &:last-of-type{
    //             padding-bottom: 0;
    //         }
    //     }
    // }
    // &.table--detail--clickable{
    //     &>tr{
    //         &>td{
    //             cursor: pointer;
    //         }
    //         &:hover{
    //             td{
    //                 background-color: rgba(244, 246, 249, .4);
    //             }
    //         }
    //     }
    // }
    &>tr{
        &>th{
            white-space: nowrap;
            color: var(--secondary-color);
            font-size: variables.$fontsize-s;
            line-height: 21px;
            padding-left: 12px;
            padding-right: 12px;
            padding-top: 8px;
            padding-bottom: 8px;
            background-color: rgba(244, 246, 249, 1);
            &:first-of-type{
                border-top-left-radius: 4px;
            }
            &:last-of-type{
                border-top-right-radius: 4px;
            }
        }
        &>td{
            transition: .1s ease;
            font-size: variables.$fontsize-s;
            line-height: 21px;
            border-bottom: 1px solid #E6E6E6;
            border-top: none;
            color: #7C8590;
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 12px;
            padding-right: 12px;
            &.td--label{
                color: var(--secondary-color);
            }
            &.td-passive{
                color: #949494 !important;
            }
            &:first-of-type{
                // padding-left: 0;
            }
            &:last-of-type{
                // padding-right: 0;
            }
            .title{
                width: 100%;
                display: block;
            }
            .subtitle{
                width: 100%;
                display: block;
                // color: #151515;
                color: #4F5563;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
            }
        }
    }
    &.table--detail--dash{
        border-radius: 4px;
        border: 1px solid #EAF0F3;
        background-color: #fff;
        border-collapse: separate;
        &>tr{
            &>th{
                line-height: 21px;
                padding: 10px 16px;
                background-color: transparent;
                border-bottom: 1px solid #EBEBEC;
                font-weight: 500;
            }
            &>td{
                line-height: 21px;
                padding: 12px 16px;
                border-bottom: 1px solid #E9EEF0;
                color: #151515 !important;
                background-color: #FCFCFF;
                background-color: #fff;
                strong{
                    color: variables.$text-color;
                    font-weight: 500;
                }
                &.td--status{
                    span{
                        color: #4F5563;
                    }
                }
            }
            &:last-of-type td{
                border-bottom: none;
                &:first-of-type{
                    border-bottom-left-radius: 4px;
                }
                &:last-of-type{
                    border-bottom-right-radius: 4px;
                }
            }
            &:last-of-type th{
                &:first-of-type{
                    border-top-left-radius: 4px;
                }
                &:last-of-type{
                    border-top-right-radius: 4px;
                }
            }
            // &:nth-of-type(2n) td{
            //     background-color: #fff;
            // }
        }
        &.table--detail--dash--buttons{
            &>tr{
                &>th{
                    background-color: #F9F9FA;
                }
                &>td{
                    color: #989898;
                    // vertical-align: middle;
                }
            }
        }
       
    }
   
}
