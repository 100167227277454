@use "./variables.scss";

.popover--search{
    .popover-arrow{
        display: none !important;
    }
    .popover-body{
        padding: 0;
        border: 1px solid #D7DDE4;
        border-radius: 4px;
        box-shadow: 0px 1px 6px 0px #00000026;
        background-color: #fff;
        transform: translateY(-3px);
        overflow: visible;

        .popover--inner{
            .options{
                padding: 4px 16px;

                .option{
                    cursor: pointer;
                    padding-top: 6px;
                    padding-bottom: 6px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;

                    .title{
                        width: max-content;
                        max-width: calc(100vw - 32px);
                    }
                }
            }
        }
    }

    .clear-wrap{
        width: max-content;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #D7DDE4;
        padding: 8px 16px;
        cursor: pointer;
        color: #929292;
        text-decoration: underline;
    }
}


// search period
.popover--search-period{
    .popover-body{
        border: 1px solid #D7DDE4;
        border-radius: 4px;
        padding: 16px;
        box-shadow: 0px 1px 6px 0px #00000026;
        width: 307px;
        background-color: #fff;
        transform: translateY(-3px);
        overflow: visible;
        max-width: calc(100vw - 32px);
        .popover--inner{
            margin-top: -10px;
        }
        .popover-row{
            display: flex;
            cursor: pointer;
            padding-left: 16px;
            padding-right: 16px;
            margin-left: -16px;
            margin-right: -16px;
            width: calc(100% + 32px);
            cursor: pointer;
            .radio-wrap{
                padding-top: 9px;
                padding-right: 6px;
            }
            .value-wrap{
                display: flex;
                flex-direction: column;
                .descr{
                    font-size: 14px;
                    line-height: 24px;
                    min-height: 36px;
                    display: flex;
                    align-items: center;
                }
                .range{
                    display: flex;
                    align-items: center;
                    margin-right: 6px;
                    .and{
                        margin-left: 8px;
                        margin-right: 8px;
                        color: #929BA4;
                    }
                }
            }
        }
        .field-date{
            input{
                min-height: 32px !important;
                background-size: 13px !important;
                padding-right: 24px !important;
                background-position: center right 7px !important;
                background-image: url(/assets/img/icons/icon-cal-input.svg) !important;
            }
        }
        .clear-wrap{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #D7DDE4;
            padding: 0 16px;
            margin-left: -16px;
            margin-right: -16px;
            width: calc(100% + 32px);
            cursor: pointer;
            margin-top: 16px;
            padding-top: 8px;
            margin-bottom: -8px;
            text-decoration: none;

            font-size: 14px;

            .confirm{
                display: inline-block;
                align-items: center;
                padding: 0 10px;
                background-color: #1a2846;
                color: #fff;
                letter-spacing: 1px;
                border-radius: 4px;
                transition: 0s ease !important;
                text-decoration: none;
            }
            .clear{
                justify-self: flex-end;
                color: #929292;
                text-decoration: underline;
                background-color: transparent;
                padding: 0;
            }
        }
    }
}

.actions-popover-button{
    display: flex;
    align-items: center;
    i{
        background-color: #EAF0F3;
        border-radius: 100px;
        padding: 4px;
        font-size: 14px;
        transition: 0.1s ease;
        flex-grow: 1;
        &:hover{
            background-color: #dde5ea;
            cursor: pointer;
        }
    }
}

.actions-popover{
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: 0;
    .arrow{
        display: none;
    }
    .popover-body{
        padding: 0;
        ul{
            margin-bottom: 0;
            list-style-type: none;
            margin-top: 0;
            padding-left: 0;
            li{
                color: #8C959E;
                cursor: pointer;
                padding: 12px 16px;
                border-bottom: 1px solid #F3F3F3;
                text-align: left;
                line-height: 21px;
                white-space: nowrap;
                &:hover{
                    background-color: #F9F9FA;
                }
                i{
                    background-color: #FFFF;
                    font-size: 12px;
                    margin-right: 7px;
                }
                &:last-child{
                    border-bottom: none;
                }
            }
        }
    }
}