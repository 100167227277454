@use "./assets/scss/reset.scss";
@use 'bootstrap/scss/bootstrap.scss';
@use 'quill/dist/quill.snow.css';
@use 'ngx-toastr/toastr';
@use "./assets/scss/variables.scss";
@use "./assets/scss/helpers.scss";
@use "./assets/scss/animations.scss";
@use "./assets/scss/editor.scss";
@use "./assets/scss/tooltips.scss";
@use "./assets/scss/ie.scss";
@use "./assets/scss/icons.scss";
@use "./assets/scss/shared.scss";
@use "./assets/scss/fields.scss";
@use "./assets/scss/richtext.scss";
@use "./assets/scss/tables.scss";
@use "./assets/scss/buttons.scss";
@use "./assets/scss/popovers.scss";
@use "./assets/scss/top-bar.scss";
@use "./assets/scss/im-webfont.scss";
@use "./assets/scss/fonts.scss";


// general 
::placeholder {
    color: #ACB5C1;
    opacity: 1;
}
[contenteditable] {
    outline: 0px solid transparent;
}
.theme--dark {
    ::placeholder {
        color: #050505;
    }
}
em {
    font-style: italic;
  }
ol, ul{
    padding-left: 0;
}
body {
    font-size: variables.$fontsize-s;
    line-height: 1.2;
    background: #fff;
}
.background{
    background: #fff;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}
body * {
    // font-family: proxima-nova, sans-serif;
    font-family: 'Roboto', sans-serif;
}
p {
    margin-bottom: 0;
}
a{
    word-break: break-word;
}
h1, h2, h3, h4{
    margin: 0;
    color: variables.$text-color;
}
h1, h1>span{
    font-size: 32px;
    font-weight: 400;
    font-weight: 500;
}
.ql-snow .ql-tooltip {
    position: absolute;
    transform: translate(105px, 10px);
}
h2{
    // font-family: "uni_neuebold", sans-serif;
    font-size: 26px;
}
h3{
    // font-family: "uni_neueregular", sans-serif;
    font-size: 20px;
}
h4{
    // font-family: "uni_neueregular", sans-serif;
    font-size: variables.$fontsize-l;
}
.ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
// newsletter preview custom
.link-info{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 32px;
    height: 32px;
    border-radius: 99px;
    background-color: #50C5B7;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.16);
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-100%) translateX(100%);
    z-index: 3;
    .link-info-inner{
        min-width: 24px;
        height: 24px;
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
        padding-left: 5px;
        padding-right: 5px;
        color: #373F51;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: #EEF9F8;
        border-radius: 99px;
    }
    &::after{
        content: "";
        width: 16px;
        height: 16px;
        display: block;
        background-color: #50C5B7;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(0px, 0px);
        z-index: -1;
        border-radius: 2px;
    }
}
// .uni_neuelight{
//     font-family: "uni_neuelight", sans-serif;
// }
// .uni_neueregular{
//     font-family: "uni_neueregular", sans-serif;
// }
// .uni_neuebold{
//     font-family: "uni_neuebold", sans-serif;
// }
ul, ol{
    margin-bottom: 0;
    &.display-value{
        &>li{
            padding: 8px;
            padding-left: 0;
            padding-right: 0;
            border-bottom: 1px solid #E5E5E5;
            &:first-of-type{
                margin-top: 8px;
                border-top: 1px solid #E5E5E5;
            }
        }
    }
}
ul li {color: variables.$text-color;}
ol li {color: variables.$text-color;}
.scroll-y{
    overflow-y: auto;
}
.scroll-x{
    overflow-x: auto;
}
.subtitle-m{
    color: #acacac;
    font-size: variables.$fontsize-l;
}
// .sep{
//     width: 56px;
//     height: 2px;
//     display: block;
//     background-color: var(--primary-color);
// }
main{
    min-height: 100vh;
    color: variables.$text-color;
    background: #fff;
    position: relative;
    // &.busy{
    //     cursor: progress;
    // }
    .busy-overlay{
        cursor: progress;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.2);
        z-index: 999999999;
    }
}
.container {
    // max-width: 1680px !important;
    // max-width: 1920px !important;
    // max-width: 3840px !important;
    padding: 0;
    position: relative;
    margin: unset;
}

.tab{
    display: inline-block;
    margin-left: 20px;
}

.page{
    padding: 28px 40px 40px 40px;
}

.descr{
    color: variables.$gray;
    font-weight: 300;
}

// helpers
.saving{
    opacity: .6;
    pointer-events: none;
}
.saving--pre{
    transition: .1s ease;
}
.opacity-hide{
    transition: .1s ease;
    opacity: 0;
    pointer-events: none;
}
.opacity-show{
    opacity: 1 !important;
    pointer-events: all !important;
}
.lh-1{
    line-height: 1;
}
.h-100{
    height: 100%;
}
.uppercase{
    text-transform: uppercase;
}

// toasts
#toast-container{
    .ngx-toastr{
        // box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.15) !important;
        // box-shadow: 0 6px 16px 0 rgba(144, 144, 144, 0.3) !important;
        box-shadow: 0 3px 12px 0 rgba(144, 144, 144, 0.2) !important;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 53px;
        padding-right: 30px;
        border-radius: 4px;
        background: #fff;
        margin: 0 0 11px;
        width: 400px;
        .toast-close-button{
            background-image: url(/assets/img/icons/x.svg);
            background-repeat: no-repeat;
            background-size: contain;
            width: 10px;
            height: 10px;
            transition: .1s ease;
            position: absolute;
            top: 50%;
            right: 13px;
            transform: translatey(-50%);
            &:hover{
                opacity: .7;
            }
            span{
                display: none;
            }
        }
    }
    .toast-success{
        border-left: 5px solid #5fbb97;
    }
    .toast-warning{
        border-left: 5px solid #ef8a17;
    }
    .toast-error{
        border-left: 5px solid variables.$error-red;
    }
    .toast-info{
        border-left: 5px solid var(--primary-color);
    }
    .toast-bottom-right {
        right: 16px;
        bottom: 0px;
    }
    .toast-title{
        font-size: variables.$fontsize-m;
        color: variables.$text-color;
        // font-family: "uni_neueregular", sans-serif;
    }
    .toast-message{
        font-size: variables.$fontsize-xs;
        color: #555555;
        margin-top: 5px;
    }
    .toast-warning{
        background-image: url(/assets/img/icons/icon-toast-warning.svg);
        background-repeat: no-repeat;
        background-size: 23px 23px;
        background-position: center left 15px;
    }
    .toast-error{
        background-image: url(/assets/img/icons/icon-toast-error.svg);
        background-repeat: no-repeat;
        background-size: 23px 23px;
        background-position: center left 15px;
    }
    .toast-info{
        background-image: url(/assets/img/icons/icon-toast-info.svg);
        background-repeat: no-repeat;
        background-size: 23px 23px;
        background-position: center left 15px;
    }
    .toast-success{
        background-image: url(/assets/img/icons/icon-toast-success.svg);
        background-repeat: no-repeat;
        background-size: 21px 21px;
        background-position: center left 15px;
    }
    
}
// display
.display-label{
    font-size: variables.$fontsize-s;
    line-height: 20px;
    margin-bottom: 4px;
    color: #909090;
    // label color
    color: var(--primary-color);
    font-weight: 400;
    &.uppercase{
        font-size: variables.$fontsize-xxs;
        font-weight: 500;
    }
    .theme--dark & {
        color: #dadddb;
    }
}
.display-row{
    // margin-bottom: variables.$spacing-m;
    &>div{
        margin-bottom: 32px;
    }
    .display-value{
        margin-bottom: variables.$spacing-xs;
        color: variables.$text-color;
        font-size: 14px;
        line-height: 24px;
        &:last-of-type{
            margin-bottom: 0;
        }
    }
    &:last-of-type{
        // margin-bottom: 0;
    }
    .display-content{
        margin-bottom: variables.$spacing-m;
        &:last-of-type{
            margin-bottom: 0;
        }
    }
}

// buttons
// .button{
//     transition: 0.2s ease;
//     color: var(--white-fixed);
//     box-shadow: none !important;
//     outline: none !important;
//     padding: 10px 15px;
//     background: var(--primary-color);
//     border-radius: 4px;
//     border: none;
//     height: 0%;
//     display: flex;
//     align-items: center;
//     &:hover{
//         background: var(--primary-color);
//         filter: brightness(1.1);
//     }
//     &.default{
//         background: var(--secondary);
//         // background: var(--primary-color);
//         &:hover{
//             background: var(--secondary);
//             // background: var(--primary-color);
//             filter: brightness(1.1);
//         }
//         // ivm
//         .theme--dark & {
//             background-color: #4FB0D4;
//         }
//     }
//     &.default--light{
//         background: var(--white);
//         background: transparent;
//         color: var(--secondary);
//         border: 2px solid var(--secondary);
//         padding: 8px 13px;
//         &:hover{
//             background: var(--secondary);
//             color: var(--white);
//             filter: none;
//         }
//         .theme--dark & {
//             // background-color: #4FB0D4;
//         }
//     }
//     &:disabled,
//     &.disabled{
//         pointer-events: none;
//         opacity: .7;
//         // background: var(--shade-2);
//     }
//     .im-icon{
//         font-size: variables.$fontsize-xxs;
//         margin-right: 7px;
//     }
//     &.cancel{
//         padding: 8px 11px;
//         background: var(--popup-background);
//         color: var(--shade-7);
//         font-size: variables.$fontsize-s;
//         border: 1px solid var(--shade-7);
//     }
//     &.confirm{
//         padding: 8px 11px;
//         background: var(--red-light);
//         color: var(--white-fixed);
//         font-size: variables.$fontsize-s;
//         border: 1px solid var(--red-light);
//     }
// }
// .button-link{
//     color: var(--secondary);
//     height: 0%;
//     display: flex;
//     align-items: center;
//     &:hover span{
//         text-decoration: underline;
//     }
//     &:disabled,
//     &.disabled{
//         pointer-events: none;
//         opacity: .7;
//     }
// }
button .im-icon{
    font-size: variables.$fontsize-xxs;
    margin-right: 5px;
    text-decoration: none !important;
}
.button-link .im-icon{
    transform: translatey(-0.5px);
    font-size: 10px;
    margin-right: 4px;
}
.export-btn .im-icon{
    font-size: variables.$fontsize-xxs;
    margin-right: 5px;
}
.export-btn {
    text-decoration: none !important;
}
.export-btn:hover span {
    text-decoration: underline !important;
}
.button-row-sep{
    background: var(--shade-3);
    width: 1px;
    align-self: stretch;
}
.hightlight-hover{
    transition: .2s ease;
    &:hover{
        filter: brightness(1.1);
    }
}

.arrow-right {
    width: 0;
    height: 0;
    border-top: 3px solid transparent;
    border-left: 4px solid #909090;
    border-bottom: 3px solid transparent;
    display: inline-block;
    margin-left: 8px;
    margin-right: 8px;
    margin-left: 8px;
    // vertical-align: top;
    // margin-top: 10px;
    transform: translatey(-1px);
}

// modals
.modal{
    .close-modal-outside{
        position: absolute;
        right: 3px;
        top: -28px;
        // padding: 8px;
        // margin: -8px;
        cursor: pointer;
        transition: none;
        .im-icon{
            transition: none;
            font-size: 16px;
            color: #fff;
        }
    }
    .close-modal{
        position: absolute;
        right: 8px;
        top: 8px;
        padding: 10px;
        cursor: pointer;
        .im-icon{
            font-size: 20px;
            color: var(--shade-6);
        }
    }
    &.main-modal .modal-dialog{
        max-width: 600px;
    }
    &.medium-modal .modal-dialog{
        max-width: 1000px;
        .modal-content{
            min-height: 500px !important;
            padding: 25px;
        }
    }
    &.detail-modal .modal-dialog{
        width: calc(100% - #{variables.$spacing-xxl});
        height: calc(100% - #{variables.$spacing-xxl});
        max-width: calc(1920px - #{variables.$spacing-xxl});
        margin-top: 64px;
        margin-bottom: variables.$spacing-m;
        .modal-content{
            height: 100%;
            .theme--dark & {
                background-color: var(--background-color);
            }
        }
    }
    &.add-modal .modal-dialog{
        max-width: 1000px;
        .modal-content{
            min-height: 400px !important;
        }
    }
    &.confirm-modal{
        .modal-content{
            min-height: unset !important;
            padding: 30px;
        }
        .modal-dialog{
            margin-top: 140px;
        }
    }
    &.detail-modal--create .modal-dialog{
        max-width: calc(1920px - #{variables.$spacing-xxl + variables.$sidePanelWidth});
    }
    &.modal--theme-images .modal-dialog{
        width: fit-content;
        max-width: 1080px;
    }

    &.sub-modal .modal-dialog{
        width: calc(100% - #{variables.$spacing-xxl});
        height: calc(100% - #{variables.$spacing-xxl});
        max-width: calc(1500px - #{variables.$spacing-xxl});
        margin-top: variables.$spacing-m;
        margin-bottom: variables.$spacing-m;
        .modal-content{
            height: 100%;
        }
    }
    &.options-modal .modal-dialog{
        max-width: 800px;
        .modal-content{
            min-height: 420px;
        }
    }
    .modal-dialog{
        height: auto !important;
        // height: calc(100% - #{variables.$spacing-xxl}) !important;
        overflow: auto !important;
        border-radius: 6px !important;
        margin-top: 80px;
        box-shadow: 0px 0px 16px 0px rgb(0 0 0 / 20%);
        overflow: visible !important;
        .modal-content{
            overflow: visible !important;
            border-radius: 6px !important;
            // padding: 25px 30px;
            border: none;
            // box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
            overflow-x: hidden;
            min-height: 600px;
            // background-color: transparent !important;
        }
        .modal-header {
            border: none;
            align-items: center;
        }
        .modal-body {
            border: none;
        }
        .modal-footer {
            border: none;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
        .modal-wrap{
            border-radius: 6px;
            overflow: hidden;
            background-color: #fff;
        }

    }
}

// forms
.row-form{
    margin-bottom: 20px;
    &:last-of-type{
        margin-bottom: 0;
    }
}

// // fields
// .field {
//     .label{
//         color: var(--text-color);
//         font-size: variables.$fontsize-s;
//         display: inline-block;
//         display: block;
//         margin-bottom: 9px;
//         font-weight: 600;
//         span{
//             font-weight: 300;
//             color: var(--shade-6);
//             margin-left: 3px;
//         }
//     }
//     .after-string{
//         margin-left: 12px;
//         font-size: variables.$fontsize-s;
//         white-space: nowrap;
//     }
//     .error-field{
//         border: 1px solid var(--red) !important;
//     }
//     .invalid-field{
//         background-color: var(--red-background);
//     }
//     .error-field-display {
//         display: block;
//         font-size: variables.$fontsize-s;
//         color: var(--red);
//         line-height: normal;
//         padding-top: 2px;
//     }
// }

// // datepicker
// .myDpSelector{
//     bottom: calc(100% + 4px)!important;
//     top: auto !important;
//     left: -1px !important;
//     border: 1px solid var(--shade-2) !important;
//     box-shadow: 0 3px 12px 0 rgba(144, 144, 144, 0.2) !important;
//     padding: 6px 7px !important;
//     .theme--dark & {
//         border-color: #6f747b !important;
//     }
// }
// .myDpSelectorArrow, .myDpMonthYearSelBar, .myDpCalTable, .myDpMonthTable, .myDpYearTable, .myDpDaycell {
//     background: var(--popup-background) !important;
// }
// .myDpWeekDayTitle{
//     color: var(--text-color) !important;
// }
// .myDpTableSingleDay:hover, .myDpTableSingleMonth:hover, .myDpTableSingleYear:hover {
//     background: var(--shade-2) !important;
//     .theme--dark & {
//         background: #656c79 !important;
//     }
// }
// .myDpMonthYearText button{
//     color: var(--text-color) !important;
//     pointer-events: none;
// }
// .myDpDayValue{
//     color: var(--text-color) !important;
//     transform: translatey(1px);
//     display: block;
// }
// .myDpNextMonth .myDpDayValue, .myDpPrevMonth .myDpDayValue{
//     color: var(--shade-6) !important;
// }
// body div .myDpSelectedDay, body div .myDpSelectedDay .myDpSelectedMonth, body div .myDpSelectedDay .myDpSelectedYear {
//     border: none !important;
//     background: var(--secondary) !important;
//     &:hover{
//         background: var(--secondary) !important;
//     }
//     border-radius: 3px !important;
//     .myDpDayValue{
//         color: white !important;
//     }
// }
// .ng-mydp table td, .ng-mydp table th {
//     border-radius: 3px;
// }
// .myDpMarkCurrDay, .myDpMarkCurrMonth, .myDpMarkCurrYear{
//     border: none !important;
// }
// .myDpMarkCurrDay::after{
//     content: "";
//     width: 100%;
//     height: calc(100% + 16px);
//     background-color: var(--shade-2);
//     position: absolute;
//     left: 0;
//     z-index: -1;
//     right: 0;
//     top: -9px;
//     bottom: 0;
//     border-radius: 3px;
//     .theme--dark & {
//         background-color: #656c79 !important;
//         border-color: #6f747b;
//     }
// }
// .myDpSelectedDay .myDpMarkCurrDay::after{
//     background: transparent;
// }
// .myDpSelectorArrow:before{
//     border-color: transparent !important;
//     border-top-color: var(--popup-background) !important;
//     border-width: 8px !important;
//     margin-left: -8px !important;
//     top: 100% !important;
//     z-index: 1 !important;
// }
// .myDpSelectorArrow:after{
//     border-color: rgba(250,250,250,0) !important;
//     border-top-color: var(--shade-2) !important;
//     border-width: 9px !important;
//     margin-left: -9px !important;
//     top: 100% !important;
//     .theme--dark & {
//         border-top-color: #6f747b !important;
//     }
// }
// .ng-mydp *{
//     font-family: proxima-nova, sans-serif !important;
//     font-size: variables.$fontsize-s !important;
//     // color: var(--text-color) !important;
// }
// .myDpWeekDayTitle{
//     font-size: variables.$fontsize-s !important;
//     font-weight: 600 !important;
// }
// .myDpIconLeftArrow {
//     font-family: "imengine" !important;
// }
// .myDpIconRightArrow {
//     font-family: "imengine" !important;
// }
// .myDpIconLeftArrow:before {
//     content: "\ea03" !important;
// }
// .myDpIconRightArrow:before {
//     content: "\ea04" !important;
// }
// .myDpIcon{
//     font-size: 13px !important;
//     color: var(--text-color) !important;
// }
// .myDpMonthYearText *{
//     font-weight: 600 !important;
// }
// .myDpMonthYearSelBar{
//     margin-bottom: 7px !important;
// }
// .myDpNoFooter {
//     height: calc(100% - 35px) !important;
// }
// .myDpDaycell:focus, .myDpMonthcell:focus, .myDpYearcell:focus{
//     box-shadow: inset 0 0 0 1px var(--shade-2) !important;
//     .theme--dark & {
//         box-shadow: inset 0 0 0 1px #656c79 !important;
//     }
// }

// profile popover
.profile-popover{
    min-width: 260px;
    border-top: 2px solid var(--primary-color);
    &::after{
        content: '';
        border-style: solid;
        border-width: 0 4px 4px 4px;
        border-color: transparent transparent var(--primary-color) transparent;
        position: absolute;
        bottom: 100%;
        right: 16px;
    }
    .head{
        background: #F5F5F5;
    }
    .options{
        ul{
            li{
                border: none;
                background: none !important;
                padding: 0;
                &.logout{
                    a{
                        color: variables.$gray !important;
                    }
                }
                &:last-of-type{
                    a div{
                        border-bottom: none;
                        transition: .2s ease;
                    }
                    &:hover{
                        a div{
                            color: variables.$text-color;
                        }
                    }
                }
                a{
                    &:hover{
                        background: rgb(251, 251, 251);
                    }
                    div{
                        border-bottom: 1px solid #eaeaea;
                        // padding-top: 12px;
                        // padding-bottom: 12px;
                    }
                    width: 100%;
                    transition: .1s ease;
                    font-size: 14px;
                    line-height: 24px;
                    color: variables.$text-color;
                    padding-left: 18px;
                    padding-right: 18px;
                    text-decoration: none;
                    * {
                        font-size: 16px;
                    }
                    span{
                        margin: 0;
                        min-width: 27px;
                        i{
                            transition: .2s ease;
                            font-size: 16px;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

// side panel
.side-panel{
    padding: variables.$spacing-m variables.$spacing-l variables.$spacing-s variables.$spacing-l;
    width: variables.$sidePanelWidth;
    background: var(--shade-2);
    border-right: 1px solid var(--shade-3);
    height: calc(100vh - #{variables.$headerHeight});
    overflow-y: auto;
    position: fixed;
    top: variables.$headerHeight;
    z-index: 2;
    .side-panel-footer{
        padding-top: variables.$spacing-s;
        font-size: variables.$fontsize-xxs;
        text-align: center;
        color: var(--shade-8);
        justify-self: end;
        width: 100%;
        .theme--dark & {
            color: var(--shade-3);
        }
        a{
            color: var(--secondary);
            text-decoration: underline;
            .theme--dark & {
                color: var(--white-fixed);
            }
        }
    }
}
.side-panel-main{
    margin-left: variables.$sidePanelWidth;
    max-width: calc(100vw - #{variables.$sidePanelWidth});
    overflow: hidden;
}
.panel-footer{
    background: #ebebeb;
    .theme--dark & {
        background: #3a3a3a;
    }
    padding-top: 28px;
    padding-bottom: 28px;
}

// bootstrap overwrites
.dropdown-menu{
    min-width: 100%;
    border-radius: 2px;
    box-shadow: 0 2px 12px 0 rgba(144, 144, 144, 0.3) !important;
    margin: 0;
    padding: 0;
    border: none;
    z-index: 1;
    max-height: 306px;
    overflow-y: auto;
    background: var(--popup-background);
    &.show{
        z-index: 3;
    }
}
.dropdown-item{
    padding: 8px 10px;
    color: var(--text-color);
}
.field-typeahead-small .dropdown-item{
    white-space: pre-wrap;
}
.dropdown-item.active, .dropdown-item:active{
    background: var(--primary-color);
}
.dropdown-item.active .typeahead-after, .dropdown-item:active .typeahead-after{
    color: #efefef;
}

// selection detail modal
// .selection-detail-modal{
//     background: var(--background-color);
//     color: var(--text-color);
//     .panel-head{
//         border-color: #6c6e6d;
//     }
//     .edit, .cancel{
//         .theme--dark & {
//             color: var(--white-fixed);
//         }
//     }
//     .modal-form-section{
//         // transition: .1s ease;
//         // &.edit-section{
//         //     background-color: #f7fbfe;
//         //     .theme--dark & {
//         //         background-color: #282828;
//         //         // background-color: var(--popup-background-alt);
//         //     }
//         // }
//     }
//     .start-form{
//         transition: .1s ease;
//         &.edit-section{
//             background-color: #f7fbfe;
//             .theme--dark & {
//                 background-color: #282828;
//                 // background-color: var(--popup-background-alt);
//             }
//         }
//     }
// }

.stamp{
    border: 1px solid #d8d8d8;
    display: inline-block;
    border-radius: 3px;
    font-size: 16px;
    line-height: 16px;
    font-size: 12px;
    transform: translateY(-1px);
    padding: 0 2px 0 2px;
    margin-left: 1px;
}

.panel-head{
    border-bottom: 1px solid #506074;
    button{
        color: #fff !important;
        i{
            color: #fff !important;
        }
    }
    .titles{
        .title{
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 4px;
        }
        .subtitle{
            font-size: variables.$fontsize-s;
            line-height: 21px;
        }
    }
}
.detail-modal-header{
    padding: 32px 40px;
    background-color: #F4F6F9;
    background-color: #F9F9FA;
    h1{
        font-size: 32px;
        line-height: 48px;
        font-weight: 500;
    }
}
.detail-modal-body{
    padding: 40px;
    
}
.detail-modal-footer{
    margin: 0 40px;
    padding: 24px 0 40px;
    border-top: 1px solid #E5E5E5;
    display: flex;
    flex-direction: column;
    gap: 8px;
    p{
        font-size: 12px;
        line-height: 14px;
        font-weight: 300;
        color: #717277;
        margin-bottom: 0;
    }
}
.active-page-icon{
    color: var(--primary-color);
    font-size: 30px;
    margin-right: 14px;
    transform: translateY(-1px);
}

.content {
    .row-pagination{
        margin: 0 !important;
    }
    .emptystate-wrap{
        margin-top: 0 !important;
        .empty-state{
            font-weight: 300;
            font-size: 14px;
            padding-top: 20px;
        }
    }
}

// side panel
.side-panel-wrap{
    padding: 24px;
    width: variables.$sidePanelWidth;
    // background: var(--shade-2);
    // border-right: 1px solid var(--shade-3);
    height: calc(100vh - #{variables.$headerHeight});
    overflow-y: auto;
    position: fixed;
    top: variables.$headerHeight;
    z-index: 2;
}
.side-panel{
    border: 1px solid #edecec;
    // padding: variables.$spacing-m variables.$spacing-l variables.$spacing-s variables.$spacing-l;
    width: variables.$sidePanelWidth;
    // background: var(--shade-2);
    // border-right: 1px solid var(--shade-3);
    // height: calc(100vh - #{variables.$headerHeight});
    // overflow-y: auto;
    // position: fixed;
    // top: variables.$headerHeight;
    // z-index: 2;

    width: 100%;

    height: auto;
    padding: 16px 16px 32px 16px;
    border-radius: 8px;
    border: none;
    background: rgba(57, 70, 86, 0.05);
    .search-title{
        margin-bottom: 24px;
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        color: var(--text-color);
    }
    .side-panel-footer{
        padding-top: variables.$spacing-s;
        font-size: variables.$fontsize-xxs;
        text-align: center;
        color: var(--shade-8);
        justify-self: end;
        width: 100%;
        .theme--dark & {
            color: var(--shade-3);
        }
        a{
            color: var(--secondary);
            text-decoration: underline;
            .theme--dark & {
                color: var(--white-fixed);
            }
        }
    }
    .field-date input{
        width: 100%;
    }
}
.side-panel-main{
    margin-left: variables.$sidePanelWidth;
    max-width: calc(100vw - #{variables.$sidePanelWidth});
    overflow: auto;
    z-index: 2;
}
.panel-footer{
    background: #ebebeb;
    .theme--dark & {
        background: #3a3a3a;
    }
    padding-top: 28px;
    padding-bottom: 28px;
}

// newsletter
.newsletter-content .mail_header_data{
    border: none !important;
}

@media only screen and (max-width: 1600px) {
    .side-panel {
      padding-left: 20px;
      padding-right: 20px;
      width: variables.$sidePanelWidth--1600;
    }
    .side-panel-main{
        margin-left: variables.$sidePanelWidth--1600;
        max-width: calc(100vw - #{variables.$sidePanelWidth--1600});
    }
    header .container{
        padding-left: 20px !important;
    }
    // .table{
    //     th, td{
    //         padding-left: variables.$spacing-xxs !important;
    //         padding-right: variables.$spacing-xxs !important;
    //         // padding-top: variables.$spacing-xxs !important;
    //         // padding-bottom: variables.$spacing-xxs !important;
    //         &:first-of-type{
    //             padding-left: variables.$spacing-xs !important;
    //         }
    //         &:last-of-type{
    //             padding-right: variables.$spacing-xs !important;
    //         }
    //     }
    // }
}

.page-content{
    h2{
        font-family: 'Poppins', sans-serif;
        font-size: 32px;
        font-weight: 600;
        line-height: 48px;
        color: #3551B1;
        margin-top: 20px;
    }
    h3{
                
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        color: #3551B1;
    }
    p{
        font-weight: 400;
        font-size: 15px;
        line-height: 25px;
        margin-bottom: 12px;
    }
    a{
        color: #3551B1;
        font-weight: 400;
        font-size: 15px;
        text-decoration: underline;
    }

    ol {
        display: block;
        list-style-type: decimal;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 0;
        margin-right: 0;
        padding-left: 40px;
    }
    ul {
        display: block;
        list-style-type: disc;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 0;
        margin-right: 0;
        padding-left: 40px;
    }
}