@use "./variables.scss";
.tiles{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    .tile{
        width: 210px;
        border: 1px solid #E6E7EA;
        border-radius: 4px;
        padding: 16px 24px;
        .title{
            margin-bottom: 16px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }
        .value-wrap{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .value{
                font-weight: 600;
                font-size: 30px;
                line-height: 32px;
                &.value--neg{
                    color: #EB767D;
                }
            }
            .percentage{
                font-size: 16px;
                line-height: 22px;
                color: #50C5B7;
                &.percentage--neg{
                    color: #EB767D;
                }
                &.percentage--neutral{
                    color: #4F5563;
                }
            }
        }
    }
}

.chart-legend-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
    app-field-select{
        display: block;
        width: 350px;
    }
    .title{
        color: #151515;
        font-size: 16px;
        line-height: 24px;
    }
    .legend-wrap{
        display: flex;
        align-items: center;
        .legend-item{
            display: flex;
            align-items: center;
            margin-left: 24px;
            i{
                width: 16px;
                height: 16px;
                border-radius: 99px;
                margin-right: 8px;
                display: inline-block;
                &.green{
                    background-color: #50C5B7;
                }
                &.red{
                    background-color: #E54852;
                }
                &.darkgreen{
                    background-color: #407D8B;
                }
                &.blue{
                    background-color: #4FB0D4;
                }
            }
            span{
                color: #4F5563;
                font-size: 14px;
                line-height: 21px;
            }
        }
    }
}

.link-wrap{
    padding: 12px 0;
    display: flex;
    border-bottom: 1px solid #EAF0F3;
    justify-content: space-between;
    a{
        font-size: 14px;
        line-height: 21px;
        color: #4E85D8;
        display: inline-block;
    }
    span{
        flex-shrink: 0;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        padding-left: 32px;
    }
}
.links-wrap{
    &>button{
        font-weight: 16px;
        line-height: 24px;
        margin-top: 16px;
        margin-bottom: 8px;
        text-decoration: underline;
    }
}