@use "./variables.scss";
// im webfont
.im-icon{
    transition: .2s ease;
    font-size: variables.$fontsize-l;
    &.icon--size-18{
        font-size: 18px !important;
    }
    &.icon--size-16{
        font-size: 16px !important;
    }
    &.icon--size-14{
        font-size: 14px !important;
    }
    &.icon--size-12{
        font-size: 12px !important;
    }
    &.icon--size-9{
        font-size: 9px !important;
    }
    &.icon--size-7  {
        font-size: 7px !important;
    }
    &.icon--size-6{
        font-size: 6px !important;
    }
}

// customize
.icon-close{
    color: var(--shade-5);
    font-size: 24px;
}


// action icons
.action-icon{
    width: 56px;
    height: 56px;
    background: var(--white-fixed);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 2px solid #efefef;
    .theme--dark & {
        border-color: #909090;
    }
    transition: .2s ease;
    cursor: pointer;
    i{
        font-size: 25px;
    }
}