@use "./variables.scss";
.advanced-search-backdrop{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    opacity: .4;
    opacity: 0;
    z-index: 20;
    cursor: pointer;
}
.top-bar{
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
    align-items: flex-start;
    .search-wrap{
        // *{
        //     font-size: 12px;
        //     font-weight: 300;
        // }
        &>div .button-link{
            font-size: 14px;
        }
        .search-bar-wrap{
            margin-right: 16px;
            position: relative;
            // width: 240px;
            &>app-field-text .field-text{
                input{
                    font-size: 14px;
                    font-weight: 400;
                    width: 240px;
                    background-color: #FFFFFF;
                    border-color: #E5E5E5;
                    padding: 6px 24px 6px 8px;
                    background-image: url(/assets/img/icons/icon-search-top-bar.svg);
                    background-repeat: no-repeat;
                    background-size: 16px 16px;
                    background-position-x: 216px;
                    background-position-y: 11px;
                    &::placeholder{
                        color: #8D959E;
                    }
                }
            }
            &>app-field-select{
                select{
                    margin-left: 16px;
                    font-size: 14px;
                    font-weight: 400;
                    max-width: 340px;
                    width: 100%;
                    background-color: #F9F9FA !important;
                    border-color: #E5E5E5;
                }
                .model-empty{
                    color: #8D959E;
                }
            }
            .advanced-search-wrap{
                position: absolute;
                top: 0;
                left: 0;
                border: 1px solid #E5E5E5;
                border-radius: 4px;
                background-color: #F9F9FA;
                box-shadow: 0px 6px 14px 0px rgb(0 0 0 / 12%);
                z-index: 21;
                padding: 10px 16px;
                padding-bottom: 24px;
                width: 800px;
                *{
                    font-size: 14px;
                    font-weight: 400;
                }
                &.advanced-search-wrap--compact{
                    width: 400px;
                }
                .title{
                    display: flex;
                    justify-content: space-between;
                    &>span{
                        color: #394756;
                        margin-bottom: 22px;
                        font-size: 20px;
                        line-height: 28px;
                        font-weight: 500;
                    }
                    .x{
                        cursor: pointer;
                        padding-left: 6px;
                        padding-right: 6px;
                        margin-left: -6px;
                        margin-right: -10px;
                        i{
                            font-size: 17px;
                        }
                    }
                }
                .display-row{
                    &>div{
                        margin-bottom: 24px;
                    }
                }
                .sep{
                    border-top: 1px solid #E5E5E5;
                    padding-top: 24px;
                    // margin-top: 4px;
                }
                .foot{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-top: 0;
                    justify-content: right;
                    .button{
                        // font-size: 16px;
                        // font-weight: 400;
                        // letter-spacing: .5px;
                        // min-height: 47px;
                        // min-width: 150px;
                        font-size: 14px;
                        font-weight: 400;
                        letter-spacing: 1px;
                        min-height: 44px;
                        min-width: 130px;
                    }
                    .button-link{
                        margin-right: 24px;
                        line-height: 20px;
                    }
                }
            }
        }
        &>div{
            &>button{
                font-weight: 400;
            }
        }
    }
    .search-sub-wrap{
        margin-top: 16px;
        .tags{
            padding-right: 16px;
            .tag{
                margin-right: 10px;
                margin-bottom: 10px;
                border-radius: 4px;
                background-color: variables.$tertiary-color-lightest;
                color: variables.$tertiary-color;
                padding: 5px 3px 5px 8px;
                font-size: 14px;
                cursor: default;
                &:last-of-type{
                    margin-right: 0;
                }
                &>span{
                    white-space: nowrap;
                    .title{
                        font-weight: 300;
                    }
                    .value{
                        font-weight: 500;
                    }
                }
                &>i{
                    font-size: 11px;
                    cursor: pointer;
                    padding-top: 1px;
                    padding-bottom: 1px;
                    padding-left: 4px;
                    padding-right: 4px;
                    margin-left: 4px;
                }
            }
        }
        &>div{
            &>button{
                font-weight: 400;
                margin-top: 5px;
            }
        }
    }
    .portal-link{
        font-size: 18px;
        text-decoration: none !important;
        margin: -8px;
        padding: 8px;
        margin-right: 12px;
        *{color: #333; transition: .05s ease;}
        &:hover{
            *{color: rgb(99, 101, 107);}
        }
    }
    .profile-wrap{
        z-index: 1;
    }
}