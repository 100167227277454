// @font-face {
//     font-family: 'uni_neuebold';
//     src: url('../font/unineuebold-webfont.woff2') format('woff2'),
//          url('../font/unineuebold-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'uni_neuebook';
//     src: url('../font/unineuebook-webfont.woff2') format('woff2'),
//          url('../font/unineuebook-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'uni_neueregular';
//     src: url('../font/unineueregular-webfont.woff2') format('woff2'),
//          url('../font/unineueregular-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'uni_neuelight';
//     src: url('../font/unineuelight-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

@font-face {
    font-family: "Gotham";
    src: url('../font/Gotham-Book.otf') ;
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Gotham";
    src: url('../font/Gotham-Medium.otf') ;
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Gotham";
    src: url('../font/Gotham-Bold.otf') ;
    font-weight: 700;
    font-style: normal;
}