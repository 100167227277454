@use "./variables.scss";
.ql-container{
    font-size: 100%;
}

.ql-container .ql-editor{
    display: block;
    width: 100%;
    padding: 8px 15px;
    font-size: 14px;
    line-height: 24px;
    color: #151515;
    // min-height: 64px;
}

 .ql-toolbar.ql-snow{
    border: 1px solid variables.$field-border-color !important;
    border-bottom: none !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    background-color: var(--primary-color-light);

}
quill-editor{
    width: 100%;
}
quill-editor .ql-container.ql-snow{
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
}

.products quill-editor .ql-container.ql-snow{
    background: #FFF;
}

.changerequest-wrap quill-editor .ql-container.ql-snow, .changerequest-wrap quill-editor .ql-container.ql-snow .ql-editor{
    background: #FFF;
    min-height: 180px !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.ql-editor.ql-blank::before{
    font-style: normal;
    color: #ACB5C1;
}

.richtext-output{
    display: block;
    white-space: pre-wrap;
    .ql-editor{
        padding: 0;
    }
    p{
        color: #4F5563;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
    }
}

.field-richtext{
    .ql-toolbar.ql-snow{
        display: none !important;
    }
    &.field-richtext--expanded{
        .ql-toolbar.ql-snow{
            display: block !important;
        }
        quill-editor .ql-container.ql-snow{
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
            border-bottom-left-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
        }
        .ql-tooltip{
            z-index: 9;
            border: none;
            color: #333333;
            box-shadow: 0 0 13px 0 rgb(0 0 0 / 20%);
            border-radius: 2px;
            font-size: 14px;
            padding: 4px 14px;
            .ql-action{
                &::after{
                    content: 'Wijzig';
                    color: #3551B1;
                    text-decoration: underline;
                    font-size: 12px;
                }
            }
            .ql-remove{
                &::before{
                    content: 'Verwijder';
                    color: #3551B1;
                    text-decoration: underline;
                    font-size: 12px;
                }
            }
            input[type=text]{
                font-size: 12px;
                height: 26px;
                border-radius: 3px;
                border-color: variables.$field-border-color;
                outline: none !important;
            }
            &::before{
                content: 'Link:';
                margin-right: 5px;
                display: none;
            }
            .ql-preview{
                color: var(--primary-color);
            }
        }
        .ql-editor{
            min-height: 64px;
            h2{
                font-family: 'Poppins', sans-serif;
                font-size: 32px;
                font-weight: 600;
                line-height: 48px;
                color: #3551B1 !important;
                margin-top: 20px;
            }
            h3{
                        
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;
                color: #3551B1 !important;
            }
            p{
                font-weight: 400;
                font-size: 15px;
                line-height: 25px;
                margin-bottom: 12px;
            }
            a{
                color: #3551B1 !important;
                font-weight: 400;
                font-size: 15px;
                text-decoration: underline;
            }
            ol{
                display: block;
                list-style-type: decimal;
                margin-top: 1em;
                margin-bottom: 1em;
                margin-left: 0;
                margin-right: 0;
                padding-left: 40px;
            }
        }
    }
}

.meldpunt-richtext{
    // Richtext styling according to meldpunt 
    --meldpunt-purple : #702283;
    --meldpunt-lightpurple : #FAF9FA;
    --meldpunt-limegreen : #DCE666;

    @mixin meldpunt-h1 {
        font-weight: 500 !important;
        line-height: normal !important;
        color: var(--meldpunt-purple) !important;
        font-size: 24px !important;
        margin-bottom: 16px !important;
    }
    @mixin meldpunt-h2 {
        color: var(--meldpunt-purple) !important;            
        line-height: 24px !important;                      
        font-size: 18px !important;
        font-weight: 500 !important;
        margin-bottom: 12px !important;
    }
    @mixin meldpunt-h3 {
        color: var(--meldpunt-purple) !important;            
        line-height: 24px !important;                      
        font-size: 16px !important;
        font-weight: 500 !important;
        margin-bottom: 12px !important;
    }
    

    .ql-toolbar {
        .ql-blockquote svg{
            background-color: var(--meldpunt-lightpurple);
            border-radius: 2px;
            * {
                stroke: var(--meldpunt-purple);
                fill: var(--meldpunt-purple);
            }
        }
        .ql-picker.ql-header{
            .ql-picker-options{
                .ql-picker-item{
                    font-family: 'Gotham', sans-serif !important;
                    color: #000;
                    font-weight: 400;
                    margin-bottom: 0 !important;

                    &::before{
                        font-size: 1em !important;
                    }
                    &[data-value="1"]{
                        @include meldpunt-h1;
                        margin-bottom: 0 !important;
                    }
                    &[data-value="2"]{
                        @include meldpunt-h2;
                        margin-bottom: 0 !important;
                    }
                    &[data-value="3"]{
                        @include meldpunt-h3;
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
    }

    .field-richtext.field-richtext--expanded .ql-editor, *{
        font-family: 'Gotham', sans-serif !important;
        h1{
            @include meldpunt-h1;
        }
        h2{
            @include meldpunt-h2;
        }
        h3{
            @include meldpunt-h3;
        }
        p{
            font-family: 'Gotham', sans-serif;
            color: #120A14;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 24px;
        }
        a{
            font-family: 'Gotham', sans-serif;
            color: var(--meldpunt-purple);
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-decoration-line: underline;
        }
        blockquote{
            font-family: 'Gotham', sans-serif;
            background-color: var(--meldpunt-lightpurple);
            color: var(--meldpunt-purple);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            border-left: none;
            padding: 16px;
            margin-top: 24px;
            margin-bottom: 24px;
        }
        
    
    }
    
}

body{
    .page{
        app-field-richtext{
            .field-richtext{
                quill-editor{
                    border: none !important;
                }
            }
        }
    }
}

// wrap w/ buttons
.richtext-wrap{
    position: relative;
    // .ql-container .ql-editor{
    //     padding-right: 40px;
    // }
    // .add-comment-button{
    //     position: absolute;
    //     width: 40px;
    //     height: 40px;
    //     border-radius: 4px;
    //     display: inline-flex;
    //     justify-content: center;
    //     align-items: center;
    //     bottom: 1px;
    //     right: 0;
    //     z-index: 1;
    //     cursor: pointer;
    //     &>i{
    //         transition: .1s ease;
    //         font-size: 24px;
    //         color: #DFECEF;
    //     }
    //     &:hover i{
    //         color: variables.$secondary-color-lighter !important;
    //     }
    // }
    .richtext-buttons{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 12px;
        justify-content: flex-start;
        .button--confirm{
            margin-right: 16px;
        }
        .button--cancel{}
    }
}